import Rest from '@/services/Rest';

/**
 * @typedef {LiberationService}
 */
export default class LiberationService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'liberation-rule'
}