import Rest from '@/services/Rest';

/**
 * @typedef {ClassIntegrationService}
 */
export default class ClassIntegrationService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'class-integrations'
}